/**
* Generated automatically at built-time (2024-12-03T13:03:30.413Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "buitenkeuken-black-friday",templateKey: "sites/107-34838eee-41ca-4433-97af-8191777a0b00"};